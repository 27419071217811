@import "../node_modules/bootstrap/scss/bootstrap";

// Required
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Optional
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
//@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/RobotoCondensed.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Your variable overrides
$body-bg: #fff;
$body-color: rgb(86, 84, 84);
$primary: $body-color;

$link-color: $primary;
$link-hover-color: rgba(151,0,9,1);
$navbar-light-hover-color: $link-hover-color;
$navbar-light-active-color: $link-hover-color;

$ul-bullets: $link-hover-color;

$font-size-base: 1.3rem;
$font-size-navi: 1.3rem;
$font-size-h2: $font-size-base;

$accordion-button-color: $primary;
$accordion-button-active-color: $primary;
$accordion-button-bg: transparent;
$accordion-bg: transparent;
$accordion-button-active-bg:  transparent;
$accordion-icon-color: $primary;
$accordion-icon-active-color: $primary;  
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$link-hover-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$navbar-light-toggler-border-color: transparent;
$accordion-button-focus-border-color: $primary;
$accordion-button-focus-box-shadow: none;

$transition-duration: 0.3s;
$transition-duration2: 0.3s;


// variables
$headerHeight: 12.2rem;
$headerHeightShrinked: 7rem;
$donationButtonSize: 9rem;



// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

body {
  font-family: 'Roboto Condensed', sans-serif;
}

main, section {
  .list {
    width: 90% !important;
    margin-left: 1.4rem;
  }

  ul {
    list-style-image: url('../img/leaf.svg');
  }
}

h2 {
  font-size: 1.6rem;
  font-weight: bold;
}

.navbar {
  border-bottom: 10px solid $link-hover-color;
  transition: $transition-duration;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: $font-size-navi;
}

.navbar .navbar-toggler {
  color: transparent;
  border-color: transparent;
  padding: 0.25rem 0rem;
}


@include media-breakpoint-up(xl) {
  .nav-link {
    margin-left: .75rem;
    margin-right: .75rem;
  }
}

.sm-top {
  scroll-margin-top: $headerHeightShrinked;
}

.nav-link:hover {
  font-weight: bold;
  text-decoration: none;
}

.bgleaf {
  background-image: url('../img/bgleaf.jpg');
  background-repeat: no-repeat;
  background-size: auto;
}

.bggray {
  background-color: #d9d9d9;
}

.bghands {
  background-image: url('../img/bghands.jpg');
  background-position: center;
  background-attachment: fixed;
  background-size: auto 100vh;
  @include media-breakpoint-up(lg) {
    background-size: 100vw auto;
  }  
  height: 30rem;
  text-shadow: .1rem .1rem .2rem #000;
}

.m-team {
  background-color: rgba(158,15,33,1);
  color: $white;

  .accordion {
    .accordion-header {
      .accordion-button {
        color: $white;
      }
    }
  }  

  @include media-breakpoint-up(sm) {
    .m-team-text {
      padding: 2rem 8rem 2rem 12rem;
    }
  }
}

.quote {
  color: $white;
  font-weight: bold;
  font-size: 1.8rem;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    @include media-breakpoint-up(md) {
      font-size: 3rem;
    }
  }

  vertical-align: middle;
}

.iconCard {
  margin: 1rem;
}

p {
  padding-bottom: 1.5rem;
}

.accordion {
  .accordion-button {
   // font-weight: bold;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.5;
  }

  .accordion-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.backLink::before {
  content: "◀";
  display: inline-block;
  width: 1em;
  margin-right: .5rem;
  text-decoration: none;
}

.backLink:hover {
  color: $ul-bullets;
}

.readmorecontainer {
  margin-bottom: 3rem;

  .readmorebox {
    //max-height: 12rem;
    height: 12rem;
    position: relative;
    overflow: hidden;
    transition: height .4s
  }
  .fader {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 4rem 0;
    background-image: linear-gradient(to bottom, transparent, white); 
    &.dark {
      background-image: linear-gradient(to bottom, transparent, #9e0f21); 
    }
    &.gray {
      background-image: linear-gradient(to bottom, transparent, #d9d9d9); 
    }
    &.hide {
      opacity: 0;
      transition: opacity .4s;
    }
  }
  .readmore {
    display: block;
    font-size: .9rem;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    &.hide {
      opacity: 0;
      transition: opacity .4s
    }
    &.dark {
      color: white;

      &::after {
        display: block;
        content: url('../img/arrow-down-white.svg');
      }
    }
    &.dark:hover {
      color: white;
    }

    &::after {
      display: block;
      content: url('../img/arrow-down.svg');
    }
  }
}

footer {
  background-color: rgba(151,179,20,1);
  color: $white;
  a, a:hover {
    color: $white;
  }

  ul {
    list-style-type: none;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: bold;
  }
}

.imprint {
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }
}


#logo {
  transition: $transition-duration;
  width: 100px;
}

.stage {
  margin-top: 7.7rem;
  @include media-breakpoint-up(md) {
    margin-top: 6rem;
  }
}

@include media-breakpoint-up(sm) {
  #logo {
    width: 200px;
  }

  img.shrink {
    width: 100px !important;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .navbar.shrink {
    border-bottom: 0px;
    transition: $transition-duration;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }

  .stage {
    margin-top: $headerHeight;
  }
}


.m-cards {
  .card { 
    border: 0;
    cursor: pointer;

    .card-img-overlay {
      color: $primary;
      background-color: $white;
      opacity: .9;
      transition: $transition-duration2;

      .card-title {
        font-weight: bold;
      }

      .card-text {
        font-size: $font-size-base * .7;
        line-height: 1.1;
      }
      
      &.hide {
        opacity: 0;
        transition: $transition-duration2;
      }

      a {
        font-size: $font-size-base * .7;
      }
    }
  }
}

.m-change {
  padding-top: 2rem;
  padding-bottom: 3rem;
  font-weight: bold;
  text-align: center;

  .line {
    background-image: url('../img/highlight-oneline.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.m-donate {  
  padding: 1rem 1rem;
  font-weight: bold;
  text-align: center;

  .line {
    background-image: url('../img/highlight-oneline.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.m-claims {
  .claim-icon {
    width: 10rem;
    border-radius: 1rem;
    /*border-bottom-right-radius: calc(7.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-top-left-radius: calc(7.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);*/
  }

  .card {
    width: 10rem;
    background-color: transparent;
    border: 0;
  }
}

.m-donation-button {
  position: fixed;
  bottom: 2rem;
  right: -3rem;
  transition: $transition-duration2 ease-in;
  opacity: 0;

  &.show {
    transition: $transition-duration2 ease-out;
    opacity: 1;
    right: 3rem;
  }
  
  .m-donation-button_info {
    position: absolute;
    display: inline-flex;
    right: 0;
    bottom: 0;
    width: $donationButtonSize * 2.5;
    height: $donationButtonSize;
    justify-content: center;
    flex-direction: column;
    background-color: $body-bg;
    border-bottom-right-radius: $donationButtonSize;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
    padding: 1rem;
    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2);
    transition: $transition-duration2;
    opacity: 0;

    &.show {
      opacity: 1;
      transition: $transition-duration2;
    }    
  }

  .m-donation-button_leaf {
    position: absolute;
    display: inline-flex;
    right: 0;
    bottom: 0;    
    width: $donationButtonSize;
    height: $donationButtonSize;
    background-color: $link-hover-color;
    color: $body-bg;
    font-weight: bold;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    border-top-left-radius: $donationButtonSize;
    border-bottom-right-radius: $donationButtonSize;

    box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.2);
  }
}
